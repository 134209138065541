import { Accordion, Group, List, Stack, Text } from "@mantine/core";
import { YouTubeEmbed } from "../youtubeEmbed";

export const AccordionCondicoes = () => {
  return (
    <Accordion.Item value={"condicoes"}>
      <Accordion.Control>
        <Text fw={"bold"} c={"#333"}>
          Etapa 7: Condições
        </Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Group justify="space-between" align="flex-start">
          <Stack gap={"1rem"} w={"55%"}>
            <Text>
              Nesta etapa, você deverá informar dados relacionados ao consumo de
              água, geração de efluentes e resíduos sólidos no empreendimento.
              Embora as respostas sejam obrigatórias, não é necessário que haja
              consumo ou geração desses elementos para seguir adiante.
            </Text>
            <List type="ordered">
              <List.Item>
                <Text fw={700}>Consumo de Água</Text>
                Indique se haverá ou não consumo de água no empreendimento:
                <List>
                  <List.Item>
                    <Text fw={700}>Haverá Consumo de Água:</Text>
                    <List type="ordered">
                      <List.Item>
                        Selecione esta opção se o empreendimento utilizar água
                        durante suas operações.
                      </List.Item>
                      <List.Item>
                        Especifique a{" "}
                        <Text span fw={700}>
                          origem do consumo,
                        </Text>{" "}
                        escolhendo uma das seguintes opções:
                        <List>
                          <List.Item>
                            <Text fw={700}>Rede Pública</Text>
                          </List.Item>
                          <List.Item>
                            <Text fw={700}>Captação de Água Subterrânea</Text>
                          </List.Item>
                          <List.Item>
                            <Text fw={700}>Captação de Água Superficial</Text>
                          </List.Item>
                        </List>
                      </List.Item>
                      <List.Item>
                        Informe o{" "}
                        <Text span fw={700}>
                          consumo médio diário em metros cúbicos (m³/dia).
                        </Text>
                      </List.Item>
                      <List.Item>
                        Indique o tipo de uso:
                        <List>
                          <List.Item>
                            <Text span fw={700}>
                              Doméstico:
                            </Text>{" "}
                            Uso relacionado à infraestrutura administrativa ou
                            habitacional.
                          </List.Item>
                          <List.Item>
                            <Text span fw={700}>
                              Industrial:
                            </Text>{" "}
                            Uso vinculado às operações ou processos produtivos
                            do empreendimento.
                          </List.Item>
                        </List>
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    <Text fw={700}>Não Haverá Consumo de Água:</Text>
                    <List>
                      <List.Item>
                        Selecione esta opção caso não haja previsão de
                        utilização de água no empreendimento.
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    <Text fw={700}>Não Há Previsão:</Text>
                    <List>
                      <List.Item>
                        Escolha esta opção caso as informações sobre o consumo
                        de água ainda não estejam definidas.
                      </List.Item>
                    </List>
                  </List.Item>
                </List>
              </List.Item>
              <List.Item mt={"1.5rem"}>
                <Text fw={700}>Geração de Efluentes</Text>
                Indique se o empreendimento gerará efluentes líquidos:
                <List>
                  <List.Item>
                    <Text fw={700}>Haverá Geração de Efluentes:</Text>
                    <List type="ordered">
                      <List.Item>
                        Informe o tipo de efluente gerado:
                        <List>
                          <List.Item>Doméstico</List.Item>
                          <List.Item>Industrial</List.Item>
                        </List>
                      </List.Item>
                      <List.Item>
                        Especifique a vazão média (em m³/dia) e o regime de
                        carga (contínuo ou intermitente).
                      </List.Item>
                      <List.Item>
                        Selecione o tratamento dos efluentes:
                        <List>
                          <List.Item>ETE</List.Item>
                          <List.Item>Fossa séptica</List.Item>
                          <List.Item>Fossa filtro</List.Item>
                          <List.Item>Remoção de óleos e graxas</List.Item>
                          <List.Item>Outros</List.Item>
                        </List>
                      </List.Item>
                      <List.Item>
                        Selecione o destino final:
                        <List>
                          <List.Item>Fossa séptica</List.Item>
                          <List.Item>Fossa filtro</List.Item>
                          <List.Item>Remoção de óleos e graxas</List.Item>
                          <List.Item>Outros</List.Item>
                        </List>
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    <Text fw={700}>Não Haverá Geração de Efluentes:</Text>
                    <List>
                      <List.Item>
                        Selecione esta opção caso o empreendimento não produza
                        efluentes líquidos.
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    <Text fw={700}>Não Há Previsão:</Text>
                    <List>
                      <List.Item>
                        Use esta opção se as informações sobre a geração de
                        efluentes não estiverem disponíveis.
                      </List.Item>
                    </List>
                  </List.Item>
                </List>
              </List.Item>
              <List.Item mt={"1.5rem"}>
                <Text fw={700}>Geração de Resíduos Sólidos</Text>
                Indique se o empreendimento gerará resíduos sólidos:
                <List>
                  <List.Item>
                    <Text fw={700}>Haverá Geração de Resíduos Sólidos:</Text>
                    <List type="ordered">
                      <List.Item>
                        Especifique o tipo de resíduo gerado:
                        <List>
                          <List.Item>Comum</List.Item>
                          <List.Item>Perigoso</List.Item>
                        </List>
                      </List.Item>
                      <List.Item>
                        Indique o volume médio gerado por dia (em toneladas ou
                        quilogramas).
                      </List.Item>
                      <List.Item>
                        Descreva o método de disposição final dos resíduos, como
                        coleta pública, reciclagem ou aterro sanitário.
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    <Text fw={700}>
                      Não Haverá Geração de Resíduos Sólidos:
                    </Text>
                    <List>
                      <List.Item>
                        Selecione esta opção caso o empreendimento não produza
                        resíduos sólidos.
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    <Text fw={700}>Não Há Previsão:</Text>
                    <List>
                      <List.Item>
                        Escolha esta opção caso as informações ainda não estejam
                        disponíveis.
                      </List.Item>
                    </List>
                  </List.Item>
                </List>
              </List.Item>
              <List.Item mt={"1.5rem"}>
                <Text fw={700}>Finalize a Etapa</Text>
                <List>
                  <List.Item>
                    Após preencher todas as informações obrigatórias, clique em
                    <Text fw={700} span>
                      {" "}
                      "Avançar"
                    </Text>{" "}
                    para seguir para a próxima etapa do protocolo.
                  </List.Item>
                </List>
              </List.Item>
            </List>
          </Stack>
          <YouTubeEmbed
            title="Entrando no sistema"
            link="https://www.youtube.com/watch?v=f4G_gpyG7HM&ab_channel=YouxGroup"
          />
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
