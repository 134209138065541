import { faEye, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ActionIcon,
  Button,
  Card,
  Checkbox,
  Group,
  Input,
  Select,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useEffect, useMemo, useState } from "react";
import api from "../../utils/api";
import InputMask from "react-input-mask";
import Tabela from "./tabela";
import { useNavigate } from "react-router-dom";

export const EncontreAtividade = () => {
  const navigate = useNavigate();

  const [filtroUtilizado, setFiltroUtilizado] = useState(false);
  const [tipologias, setTipologias] = useState([]);
  const [requerimento, setRequerimento] = useState([]);
  const [atividades, setAtividades] = useState([]);
  const [requerimentosFiltrados, setRequerimentosFiltrados] = useState([]);
  const [totalRequerimentos, setTotalRequerimentos] = useState(0);
  const [complementares, setComplementares] = useState([]);
  const [dispensaveis, setDispensaveis] = useState([]);
  const [totalAtividade, setTotalAtividade] = useState(0);
  const [totalComplementar, setTotalComplementar] = useState(0);
  const [totalDispensaveis, setTotalDispensaveis] = useState(0);
  const [paginationAtividade, setPaginationAtividade] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [paginationComplementar, setPaginationComplementar] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [paginationDispensaveis, setPaginationDispensaveis] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [paginationRequerimentos, setPaginationRequerimentos] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filtroAtividade, setFiltroAtividade] = useState({
    pagina: 0,
    tamanhoPagina: 10,
  });
  const [filtroComplementar, setFiltroComplementar] = useState({
    pagina: 0,
    tamanhoPagina: 10,
  });
  const [filtroDispensaveis, setFiltroDispensaveis] = useState({
    pagina: 0,
    tamanhoPagina: 10,
  });
  const [filtroRequerimentos, setFiltroRequerimentos] = useState({
    pagina: 0,
    tamanhoPagina: 10,
  });

  const getTipologias = async () => {
    try {
      const response = await api.get("/api/configurador/tipologia/findAll");
      setTipologias(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getRequerimentos = async () => {
    try {
      const response = await api.get("/api/configurador/requerimento/findAll");
      const uniqueRequerimentos = [...new Set(response.data)];
      setRequerimento(uniqueRequerimentos);
    } catch (error) {
      console.error(error);
    }
  };

  const getListaAtividades = async (complementar, filtro, dispensavel) => {
    const f = {
      palavraChave: form.values.palavraChave,
      cpfCnpj: form.values.cnpj.replace(/[./-]/g, ""),
      numeroCnae: form.values.cnae,
      nomeTipologia: form.values.tipologia,
      nomeRequerimento: form.values.requerimento,
      codigoAtividade: form.values.codigoAtividade,
      localizacao: form.values.localizacao,
      tipoPessoa: form.values.tipoPessoa,
      pagina: filtro.pagina,
      itemsPorPagina: filtro.tamanhoPagina,
      tipoOrdenacao: "nome,asc",
      complementar: complementar,
      dispensavel: dispensavel,
    };

    if (filtroUtilizado) {
      try {
        const response = await api.post(
          `/api/configurador/listar/atividades?page=${f.pagina}&size=${f.itemsPorPagina}&sort=${f.tipoOrdenacao}`,
          f
        );

        if (complementar) {
          setComplementares(response.data.content);
          setTotalComplementar(response.data.totalElements);
        } else if (dispensavel) {
          setDispensaveis(response.data.content);
          setTotalDispensaveis(response.data.totalElements);
        } else {
          setAtividades(response.data.content);
          setTotalAtividade(response.data.totalElements);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setFiltroUtilizado(true);
    }
  };

  const getListaRequerimentos = async (filtro) => {
    const f = {
      nomenclatura: form.values.requerimento,
      stringPesquisa: "",
      pagina: filtro.pagina,
      itemsPorPagina: filtro.tamanhoPagina,
      tipoOrdenacao: "fase,asc",
    };

    if (filtroUtilizado) {
      try {
        const response = await api.post(
          `/api/configurador/listar/requerimentos?page=${f.pagina}&size=${f.itemsPorPagina}&sort=${f.tipoOrdenacao}`,
          f
        );

        setRequerimentosFiltrados(response.data.content);
        setTotalRequerimentos(response.data.totalElements);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const form = useForm({
    initialValues: {
      palavraChave: "",
      cnpj: "",
      cnae: "",
      tipologia: "",
      requerimento: "",
      codigoAtividade: "",
      localizacao: "",
      tipoPessoa: [],
    },
  });

  const limparFiltros = () => {
    form.setValues({
      cnae: "",
      cnpj: "",
      codigoAtividade: "",
      localizacao: null,
      palavraChave: "",
      requerimento: null,
      tipologia: null,
      tipoPessoa: [],
    });

    setFiltroAtividade({
      pagina: 0,
      tamanhoPagina: 10,
    });

    setFiltroComplementar({
      pagina: 0,
      tamanhoPagina: 10,
    });

    setFiltroDispensaveis({
      pagina: 0,
      tamanhoPagina: 10,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      aplicarFiltros();
    }
  };

  const aplicarFiltros = () => {
    getListaAtividades(true, filtroAtividade, false);
    getListaAtividades(false, filtroComplementar, false);
    getListaAtividades(false, filtroDispensaveis, true);
    getListaRequerimentos(filtroRequerimentos);
  };

  const columnsRequerimentos = useMemo(
    () => [
      {
        accessorKey: "tipoRequerimento",
        header: "Tipo do requerimento",
        size: 80,
        minSize: 80,
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: "left",
        },
        mantineTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "faseNomenclatura",
        header: "Fase/Nomenclatura",
        size: 350,
        minSize: 350,
        maxSize: 350,
        mantineTableBodyCellProps: {
          align: "left",
        },
        mantineTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "siglaGerencia",
        header: "Gerência",
        size: 80,
        minSize: 80,
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: "left",
        },
        mantineTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "acao",
        header: "Ações",
        size: 20,
        minSize: 20,
        maxSize: 20,
        Cell: ({ row }) => {
          return (
            <FontAwesomeIcon
              icon={faEye}
              color="green"
              cursor={"pointer"}
              onClick={() =>
                navigate(`/encontre-requerimento/${row.original.id}`)
              }
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columnsAtividades = useMemo(
    () => [
      {
        accessorKey: "codigo",
        header: "Código",
        size: 40,
        minSize: 40,
        maxSize: 40,
        mantineTableBodyCellProps: {
          align: "left",
        },
        mantineTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "cnaes",
        header: "CNAE",
        size: 80,
        minSize: 80,
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: "left",
        },
        mantineTableHeadCellProps: {
          align: "left",
        },
        Cell: ({ cell, row }) => {
          return (
            <Tooltip label={row.original.cnaesCompletos}>
              <Text fz="sm" style={{ whiteSpace: "pre-wrap" }}>
                {String(cell.getValue())}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        accessorKey: "nome",
        header: "Atividade",
        size: 350,
        minSize: 350,
        maxSize: 350,
        mantineTableBodyCellProps: {
          align: "left",
        },
        mantineTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "tipologia",
        header: "Tipologia",
        size: 80,
        minSize: 80,
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: "left",
        },
        mantineTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "fases",
        header: "Fase",
        size: 80,
        minSize: 80,
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: "left",
        },
        mantineTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorKey: "acao",
        header: "Ações",
        size: 20,
        minSize: 20,
        maxSize: 20,
        Cell: ({ row }) => {
          return (
            <FontAwesomeIcon
              icon={faEye}
              color="green"
              cursor={"pointer"}
              onClick={() => navigate(`/encontre-atividade/${row.original.id}`)}
            />
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    getTipologias();
    getRequerimentos();
    getListaAtividades(true, filtroAtividade, false);
    getListaAtividades(false, filtroComplementar, false);
    getListaAtividades(false, filtroDispensaveis, true);
    getListaRequerimentos(filtroRequerimentos);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      paginationAtividade.pageIndex !== filtroAtividade.pagina ||
      paginationAtividade.pageSize !== filtroAtividade.tamanhoPagina
    ) {
      const localFiltro = {
        ...filtroAtividade,
        tamanhoPagina: paginationAtividade.pageSize,
        pagina: paginationAtividade.pageIndex,
      };
      setFiltroAtividade(localFiltro);
      getListaAtividades(false, localFiltro, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationAtividade]);

  useEffect(() => {
    if (
      paginationComplementar.pageIndex !== filtroComplementar.pagina ||
      paginationComplementar.pageSize !== filtroComplementar.tamanhoPagina
    ) {
      const localFiltro = {
        ...filtroComplementar,
        tamanhoPagina: paginationComplementar.pageSize,
        pagina: paginationComplementar.pageIndex,
      };
      setFiltroComplementar(localFiltro);
      getListaAtividades(true, localFiltro, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationComplementar]);

  useEffect(() => {
    if (
      paginationDispensaveis.pageIndex !== filtroDispensaveis.pagina ||
      paginationDispensaveis.pageSize !== filtroDispensaveis.tamanhoPagina
    ) {
      const localFiltro = {
        ...filtroDispensaveis,
        tamanhoPagina: paginationDispensaveis.pageSize,
        pagina: paginationDispensaveis.pageIndex,
      };
      setFiltroDispensaveis(localFiltro);
      getListaAtividades(false, localFiltro, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationDispensaveis]);

  useEffect(() => {
    if (
      paginationRequerimentos.pageIndex !== filtroRequerimentos.pagina ||
      paginationRequerimentos.pageSize !== filtroRequerimentos.tamanhoPagina
    ) {
      const localFiltro = {
        ...filtroRequerimentos,
        tamanhoPagina: paginationRequerimentos.pageSize,
        pagina: paginationRequerimentos.pageIndex,
      };
      setFiltroRequerimentos(localFiltro);
      getListaRequerimentos(localFiltro);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationRequerimentos]);

  return (
    <Card
      shadow="xs"
      padding={"lg"}
      radius="lg"
      style={{
        backgroundColor: "#F7F9F6",
      }}
      mt={"-3rem"}
    >
      <Stack mt={"2rem"}>
        <Text fw={"lighter"} fz={"1.25rem"} c={"#295661"}>
          Encontre sua atividade
        </Text>
        <Text fw={"lighter"} c={"#295661"}>
          Para visualizar os requisitos da atividade, primeiro selecione o CNAE
          do seu empreendimento ou faça o filtro por código, nome da atividade
          ou tipologia.
        </Text>
        <Stack gap={"1.5rem"} onKeyDown={handleKeyDown}>
          <Group justify="space-between">
            <Input
              {...form.getInputProps("palavraChave")}
              style={{ flexGrow: 1 }}
              placeholder="Digite uma palavra chave referente a atividade de seu interesse para encontra-la"
              rightSectionPointerEvents="all"
              rightSection={
                <ActionIcon variant="transparent">
                  <FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />
                </ActionIcon>
              }
            />
            <Group>
              <Button
                color="green"
                fw={"lighter"}
                radius={"xl"}
                onClick={() => {
                  aplicarFiltros();
                }}
              >
                Aplicar filtros
              </Button>
              <Button
                color="green"
                variant="subtle"
                fw={"lighter"}
                radius={"xl"}
                onClick={limparFiltros}
              >
                Limpar
              </Button>
            </Group>
          </Group>
          <Group>
            <TextInput
              {...form.getInputProps("cnpj")}
              label={
                <Text fw={"lighter"} fz={"0.875rem"}>
                  CNPJ:
                </Text>
              }
              w={"23%"}
              component={InputMask}
              mask="99.999.999/9999-99"
            />
            <TextInput
              {...form.getInputProps("cnae")}
              label={
                <Text fw={"lighter"} fz={"0.875rem"}>
                  CNAE:
                </Text>
              }
              w={"23%"}
            />
            <Select
              {...form.getInputProps("tipologia")}
              size="sm"
              label={
                <Text fw={"lighter"} fz={"0.875rem"}>
                  Tipologia:
                </Text>
              }
              w={"23%"}
              placeholder="Selecione"
              data={tipologias}
              searchable
              clearable
            />
            <Select
              {...form.getInputProps("requerimento")}
              size="sm"
              label={
                <Text fw={"lighter"} fz={"0.875rem"}>
                  Requerimento:
                </Text>
              }
              w={"23%"}
              placeholder="Selecione"
              data={requerimento}
              searchable
              clearable
            />
          </Group>
          <Group align="center">
            <TextInput
              {...form.getInputProps("codigoAtividade")}
              label={
                <Text fw={"lighter"} fz={"0.875rem"}>
                  Código ambiental:
                </Text>
              }
              w={"23%"}
            />
            <Select
              {...form.getInputProps("localizacao")}
              size="sm"
              label={
                <Text fw={"lighter"} fz={"0.875rem"}>
                  Localização:
                </Text>
              }
              w={"23%"}
              placeholder="Selecione"
              data={[
                { label: "Urbana", value: "URBANA" },
                { label: "Rural", value: "RURAL" },
                { label: "Leito de Rio", value: "LEITO_DE_RIO" },
              ]}
              clearable
              searchable
            />
            <Checkbox.Group {...form.getInputProps("tipoPessoa")}>
              <Group mt={"xl"}>
                <Checkbox
                  color="green"
                  size="sm"
                  value="PF"
                  label="Pessoa Física"
                />
                <Checkbox
                  color="green"
                  size="sm"
                  value="PJ"
                  label="Pessoa Jurídica"
                />
              </Group>
            </Checkbox.Group>
          </Group>
        </Stack>
        <Tabela
          columns={columnsAtividades}
          data={atividades}
          rowCount={totalAtividade}
          title={"Requerimentos de Licença Ambiental"}
          setPagination={setPaginationAtividade}
          state={{
            pagination: {
              pageIndex: filtroAtividade.pagina,
              pageSize: filtroAtividade.tamanhoPagina,
            },
          }}
        />
        <Tabela
          columns={columnsAtividades}
          data={complementares}
          rowCount={totalComplementar}
          title={"Demais Requerimentos"}
          setPagination={setPaginationComplementar}
          state={{
            pagination: {
              pageIndex: filtroComplementar.pagina,
              pageSize: filtroComplementar.tamanhoPagina,
            },
          }}
        />
        <Tabela
          columns={columnsAtividades}
          data={dispensaveis}
          rowCount={totalDispensaveis}
          title={"Atividade Dispensável"}
          setPagination={setPaginationDispensaveis}
          state={{
            pagination: {
              pageIndex: filtroDispensaveis.pagina,
              pageSize: filtroDispensaveis.tamanhoPagina,
            },
          }}
        />
        <Tabela
          columns={columnsRequerimentos}
          data={requerimentosFiltrados}
          rowCount={totalRequerimentos}
          title={"Outros Requerimentos Permitidos"}
          setPagination={setPaginationRequerimentos}
          state={{
            pagination: {
              pageIndex: filtroRequerimentos.pagina,
              pageSize: filtroRequerimentos.tamanhoPagina,
            },
          }}
        />
      </Stack>
    </Card>
  );
};
