import { Card, Group, Button, Popover, Text, Box, Table } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faLock } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const TabsHeader = () => {
  const navigate = useNavigate();

  const [openedSistemas, setOpenedSistemas] = useState(false);
  const [openedServiços, setOpenedServiços] = useState(false);

  return (
    <Card
      shadow="xs"
      padding={"lg"}
      h={"100%"}
      radius="lg"
      style={{
        backgroundColor: "#295661",
        zIndex: 2,
      }}
      mb={"1rem"}
      mt={"-1rem"}
    >
      <Group justify="space-between">
        <Group gap={"2rem"}>
          <Button
            variant="subtle"
            color="#ffffff"
            fw={"initial"}
            onClick={() => navigate("/")}
          >
            Página inicial
          </Button>
          <Popover
            opened={openedSistemas}
            onChange={setOpenedSistemas}
            position="bottom"
            offset={0}
            shadow="md"
            width={"350"}
          >
            <Popover.Target>
              <Button
                onClick={() => setOpenedSistemas((o) => !o)}
                variant="subtle"
                color="#ffffff"
                fw={"initial"}
                rightSection={
                  <FontAwesomeIcon icon={faChevronDown} color="#ffffff" />
                }
              >
                Sistemas
              </Button>
            </Popover.Target>
            <Popover.Dropdown
              style={{ backgroundColor: "#295661", borderColor: "#1F4751" }}
            >
              <Box>
                <Table
                  highlightOnHover
                  verticalSpacing="md"
                  highlightOnHoverColor={"#00C254"}
                >
                  <Table.Tbody>
                    <Table.Tr
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          "https://sistemas.ipaam.am.gov.br/sislam/login"
                        );
                        setOpenedSistemas(false);
                      }}
                    >
                      <Table.Td>
                        <Text c={"#ffffff"}>
                          Sistema de Licenciamento Ambiental
                        </Text>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          "https://sistemas.ipaam.am.gov.br/portal-seguranca/#/"
                        );
                        setOpenedSistemas(false);
                      }}
                    >
                      <Table.Td>
                        <Text c={"#ffffff"}>Sistema de Entrada Única</Text>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          "https://sistemas.ipaam.am.gov.br/carteira-pesca/"
                        );
                        setOpenedSistemas(false);
                      }}
                    >
                      <Table.Td>
                        <Text c={"#ffffff"}>Sistema de Carteira de Pesca</Text>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          "https://sistemas.ipaam.am.gov.br/dashboard/#/"
                        );
                        setOpenedSistemas(false);
                      }}
                    >
                      <Table.Td>
                        <Text c={"#ffffff"}>
                          DASHBOARD - Painel de Informações
                        </Text>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          "https://homologacao.ipaam.am.gov.br/sid_v2"
                        );
                        setOpenedSistemas(false);
                      }}
                    >
                      <Table.Td>
                        <Text c={"#ffffff"}>SID - AM</Text>
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/AcessoInterno");
                        setOpenedSistemas(false);
                      }}
                    >
                      <Table.Td>
                        <Text c={"#ffffff"}>Módulos internos</Text>
                      </Table.Td>
                    </Table.Tr>
                  </Table.Tbody>
                </Table>
              </Box>
            </Popover.Dropdown>
          </Popover>
          <Popover
            opened={openedServiços}
            onChange={setOpenedServiços}
            position="bottom"
            offset={0}
            shadow="md"
            width={"350"}
          >
            <Popover.Target>
              <Button
                onClick={() => setOpenedServiços((o) => !o)}
                variant="subtle"
                color="#ffffff"
                fw={"initial"}
                rightSection={
                  <FontAwesomeIcon icon={faChevronDown} color="#ffffff" />
                }
              >
                Serviços
              </Button>
            </Popover.Target>
            <Popover.Dropdown
              style={{ backgroundColor: "#295661", borderColor: "#1F4751" }}
            >
              <Box>
                <Table
                  highlightOnHover
                  verticalSpacing="md"
                  highlightOnHoverColor={"#00C254"}
                >
                  <Table.Tbody>
                    <Table.Tr
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/encontre-atividade");
                        setOpenedServiços(false);
                      }}
                    >
                      <Table.Td>
                        <Text c={"#ffffff"}>
                          Pesquisa de requisitos por atividade
                        </Text>
                      </Table.Td>
                    </Table.Tr>
                  </Table.Tbody>
                </Table>
              </Box>
            </Popover.Dropdown>
          </Popover>
          <Button
            variant="subtle"
            color="#ffffff"
            fw={"initial"}
            onClick={() => navigate("/guias-rapidos")}
          >
            Guias rápidos
          </Button>
          <Button
            variant="subtle"
            color="#ffffff"
            fw={"initial"}
            onClick={() => navigate("/duvidas")}
          >
            Dúvidas
          </Button>
          <Button
            variant="subtle"
            color="#ffffff"
            fw={"initial"}
            onClick={() => {
              window.open("https://gis.ipaam.am.gov.br/geoportal/");
            }}
          >
            Geoportal
          </Button>
          <Button
            variant="subtle"
            color="#ffffff"
            fw={"initial"}
            onClick={() => navigate("/contato")}
          >
            Contato
          </Button>
        </Group>
        <Button
          variant="outline"
          color="#ffffff"
          fw={"initial"}
          style={{ borderColor: "#14A2B8" }}
          onClick={() => {
            window.open("https://sistemas.ipaam.am.gov.br/portal-seguranca/#/");
          }}
          leftSection={<FontAwesomeIcon icon={faLock} color="#ffffff" />}
        >
          Acesso Interno
        </Button>
      </Group>
    </Card>
  );
};
