import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Button, Card, Image, Stack, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import api from "../../utils/api";
import html3pdf from "html3pdf";

import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { CardInformacoesAtividade } from "./cardInformacoes";
import { InformacoesFases } from "./informacoesFases";

export const InformacoesAtividade = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [atividade, setAtividade] = useState();
  const [fasesSelecionadas, setFasesSelecionadas] = useState("");
  const [tipoPessoaFiltro, setTipoPessoaFiltro] = useState(
    "pessoa física e jurídica"
  );

  const form = useForm({
    initialValues: {
      fases: [],
      tipoPessoa: [],
    },
  });

  const handleExportPDF = () => {
    const content = document.getElementById("exportable-box");

    const options = {
      margin: [0.1, 0.1, 1.0, 0.1],
      filename: "exported-content.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "in",
        format: "letter",
        orientation: "portrait",
      },
    };

    html3pdf()
      .set(options)
      .from(content)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        const today = new Date();
        const formattedDate = today.toLocaleDateString("pt-BR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        const pageCount = pdf.internal.getNumberOfPages();
        const footerText = `Documento gerado para a atividade ${atividade?.nome}, destinada a ${tipoPessoaFiltro} para a(s) fase(s) ${fasesSelecionadas} em ${formattedDate}`;

        pdf.setFontSize(10);
        pdf.setTextColor(100);

        for (let i = 1; i <= pageCount; i++) {
          pdf.setPage(i);
          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();

          const maxWidth = pageWidth - 1;
          const lines = pdf.splitTextToSize(footerText, maxWidth);

          let yPosition = pageHeight - 0.5;
          lines.forEach((line) => {
            pdf.text(line, pageWidth / 2, yPosition, { align: "center" });
            yPosition += 0.15;
          });
        }

        const pdfBlob = pdf.output("blob");
        const pdfURL = URL.createObjectURL(pdfBlob);
        window.open(pdfURL, "_blank");
      });
  };

  const getAtividade = async () => {
    try {
      const response = await api.get(
        `/api/configurador/atividade/findById/${id}`
      );
      setAtividade(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getAtividade();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (atividade) {
      setFasesSelecionadas(atividade?.fasesString);
    }
  }, [atividade]);

  return (
    <Stack gap={"1.5rem"}>
      <Button
        w={"10%"}
        size="lg"
        variant="subtle"
        leftSection={<FontAwesomeIcon icon={faArrowLeft} />}
        onClick={() => navigate("/encontre-atividade")}
      >
        Voltar
      </Button>
      <Card
        id="exportable-box"
        shadow="xs"
        padding={"lg"}
        radius="xs"
        style={{
          backgroundColor: "#F7F9F6",
        }}
        mt={"-1rem"}
      >
        <Stack gap={"1.5rem"}>
          <CardInformacoesAtividade
            atividade={atividade}
            fasesSelecionadas={fasesSelecionadas}
            handleExportPDF={handleExportPDF}
          />
          <InformacoesFases
            form={form}
            atividade={atividade}
            setFasesSelecionadas={setFasesSelecionadas}
            todasFasesString={atividade?.fasesString}
            setTipoPessoaFiltro={setTipoPessoaFiltro}
          />
          <Accordion
            defaultValue={"passoLicenca"}
            variant="contained"
            styles={{
              item: {
                backgroundColor: "#fff",
                "&[data-active]": {
                  backgroundColor: "#fff",
                },
              },
              chevron: {
                "&[data-rotate]": {
                  transform: "rotate(-90deg)",
                },
              },
            }}
          >
            <Accordion.Item value={"passoLicenca"}>
              <Accordion.Control>
                <Text fw={"bold"} c={"#666"}>
                  Passo a passo da licença
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Stack gap={"1.5rem"}>
                  <Stack gap={"0.5rem"}>
                    <Text>
                      <Text span fw={"bold"}>
                        1º passo:{" "}
                      </Text>
                      No SISLAM, na etapa requerimento selecione o Tipo de
                      Requerimento igual a{" "}
                      <Text span fw={"bold"}>
                        {atividade?.codigo === "0000"
                          ? "Dispensa de inexigibilidade"
                          : atividade?.tipoRequerimento}
                      </Text>
                    </Text>
                    <Text>
                      <Text span fw={"bold"}>
                        2º passo:{" "}
                      </Text>
                      No campo{" "}
                      <Text span fw={"bold"}>
                        Requerimento,
                      </Text>{" "}
                      selecione a Fase do licenciamento desejada
                    </Text>
                    <Text>
                      <Text span fw={"bold"}>
                        3º passo:{" "}
                      </Text>
                      Selecione a Tipologia{" "}
                      <Text span fw={"bold"}>
                        {atividade?.tipologia}.
                      </Text>{" "}
                      Depois, selecione a Atividade desejada inserindo o{" "}
                      <Text span fw={"bold"}>
                        Código da Atividade
                      </Text>
                    </Text>
                  </Stack>
                  <Image
                    src="/images/passos.png"
                    alt="passosLicenciamento"
                    w={"50%"}
                  />
                </Stack>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Stack>
      </Card>
    </Stack>
  );
};
