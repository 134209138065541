import React, { useState } from "react";
import {
  HeaderStyle,
  Imagem,
  Container,
  MenuContainer,
  MenuItem,
  Link,
  BotaoFechar,
  PesquisaContainer,
} from "./style";
import Logo from "../../assets/img/LOGO_GOVERNO.png";
import Input from "../input";
import CloseIcon from "../../assets/icons/CloseIcon";
import {
  ACESSIBILIDADE,
  CONTRASTE,
  MAPA_SITE,
  URL_IPAAM,
  URL_MAPA_SITE,
} from "../../utils/Constants";

const HeaderPrincipal = () => {
  const [pesquisa, setPesquisa] = useState("");

  const AlterarPesquisa = (event) => {
    setPesquisa(event.target.value);
  };

  const LimparPesquisa = () => {
    setPesquisa("");
  };

  const Pesquisar = () => {
    const url = `${URL_IPAAM}?s=${pesquisa}`;
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <HeaderStyle>
      <div>
        <a href="/">
          <Imagem src={Logo} alt="Logo Governo do Amazonas" />
        </a>
      </div>
      <Container>
        <section>
          <>
            <MenuContainer>
              <MenuItem>
                <Link href="#">{ACESSIBILIDADE}</Link>
              </MenuItem>
              <MenuItem>
                <Link href="#">{CONTRASTE}</Link>
              </MenuItem>
              <MenuItem>
                <Link href={URL_MAPA_SITE} target="_blank">
                  {MAPA_SITE}
                </Link>
              </MenuItem>
            </MenuContainer>
            <PesquisaContainer>
              <Input
                handleChange={AlterarPesquisa}
                value={pesquisa}
                handleKeyPress={Pesquisar}
              ></Input>
              <BotaoFechar onClick={LimparPesquisa}>
                <CloseIcon />
              </BotaoFechar>
            </PesquisaContainer>
          </>
        </section>
      </Container>
    </HeaderStyle>
  );
};

export default HeaderPrincipal;
