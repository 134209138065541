import { Accordion, Group, List, Stack, Text } from "@mantine/core";
import { YouTubeEmbed } from "../youtubeEmbed";

export const AccordionComplementos = () => {
  return (
    <Accordion.Item value={"complementos"}>
      <Accordion.Control>
        <Text fw={"bold"} c={"#333"}>
          Etapa 5: Complementos
        </Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Group justify="space-between" align="flex-start">
          <Stack gap={"1rem"} w={"55%"}>
            <Text>
              A etapa de{" "}
              <Text fw={700} span>
                Complementos
              </Text>{" "}
              permite adicionar atividades complementares ao requerimento
              principal. Ela é semelhante à etapa anterior, mas oferece mais
              flexibilidade para incluir atividades adicionais, sem limitações
              relacionadas ao CNAE principal da empresa.
            </Text>
            <Stack gap={"0.5rem"}>
              <Text fw={700}>Características Principais</Text>
              <List withPadding>
                <List.Item>
                  <Text span fw={700}>
                    Solicitações Livres:
                  </Text>{" "}
                  Não há filtros; qualquer atividade cadastrada no sistema pode
                  ser selecionada como complementar.
                </List.Item>
                <List.Item>
                  <Text span fw={700}>
                    Exemplos de Atividades:
                  </Text>{" "}
                  Gerenciamento de resíduos sólidos ou atividades associadas a
                  empreendimentos como shoppings.
                </List.Item>
                <List.Item>
                  Ideal para ampliar ou detalhar o escopo do requerimento.
                </List.Item>
              </List>
            </Stack>
            <Stack gap={"0.5rem"}>
              <Text fw={700}>Passo a Passo para Adicionar Complementos</Text>
              <List type="ordered" withPadding spacing={"1.5rem"}>
                <List.Item>
                  <Text fw={700}>Decida se Deseja Adicionar Complementos</Text>
                  <List type="ordered" spacing={"0.4rem"}>
                    <List.Item>
                      Escolha:
                      <List spacing={"0.4rem"}>
                        <List.Item>
                          <Text span fw={700}>
                            "Sim"
                          </Text>
                          , se desejar incluir atividades complementares.
                        </List.Item>
                        <List.Item>
                          <Text span fw={700}>
                            "Não"
                          </Text>
                          , se não houver necessidade.
                        </List.Item>
                      </List>
                    </List.Item>
                    <List.Item>
                      Clique em{" "}
                      <Text span fw={700}>
                        "Avançar"
                      </Text>{" "}
                      para prosseguir.
                    </List.Item>
                  </List>
                </List.Item>
                <List.Item>
                  <Text fw={700}>Adicionando Atividades Complementares</Text>
                  Se a opção{" "}
                  <Text span fw={700}>
                    "Sim"
                  </Text>{" "}
                  foi selecionada, siga os passos abaixo:
                  <List spacing={"0.4rem"} type="ordered">
                    <List.Item>
                      <Text fw={700}>Selecione a Tipologia e Atividade</Text>
                      <List spacing={"0.4rem"} type="ordered">
                        <List.Item>
                          Escolha a tipologia que corresponde à atividade
                          complementar.
                        </List.Item>
                        <List.Item>
                          Dentro da tipologia selecionada, escolha uma ou mais
                          atividades aplicáveis.
                        </List.Item>
                      </List>
                    </List.Item>
                    <List.Item>
                      <Text fw={700}>Informe os Parâmetros da Atividade</Text>
                      <List>
                        <List.Item>
                          Preencha os{" "}
                          <Text span fw={700}>
                            parâmetros obrigatórios
                          </Text>
                          , como:
                          <List spacing={"0.4rem"}>
                            <List.Item>
                              Área útil (em hectares, se aplicável).
                            </List.Item>
                            <List.Item>
                              Número de empregados relacionados à atividade.
                            </List.Item>
                          </List>
                        </List.Item>
                      </List>
                    </List.Item>
                    <List.Item>
                      <Text fw={700}>Adicione a Atividade</Text>
                      <List spacing={"0.4rem"}>
                        <List.Item>
                          Clique em{" "}
                          <Text span fw={700}>
                            "Adicionar Atividade"
                          </Text>{" "}
                          para registrar as informações.
                        </List.Item>
                        <List.Item>
                          Nota: Atividades adicionadas podem ser editadas ou
                          removidas nesta etapa, caso necessário.
                        </List.Item>
                      </List>
                    </List.Item>
                    <List.Item>
                      <Text fw={700}>Descreva a Atividade</Text>
                      <List>
                        <List.Item>
                          Insira uma descrição detalhada de cada atividade
                          cadastrada, explicando claramente o que será
                          executado.
                        </List.Item>
                      </List>
                    </List.Item>
                  </List>
                </List.Item>
                <List.Item>
                  <Text fw={700}>Finalize a Etapa</Text>
                  <List>
                    <List.Item>
                      Após completar as informações, clique em{" "}
                      <Text span fw={700}>
                        "Avançar"
                      </Text>{" "}
                      para seguir para a próxima etapa do protocolo.
                    </List.Item>
                  </List>
                </List.Item>
              </List>
            </Stack>
          </Stack>
          <YouTubeEmbed
            title="Entrando no sistema"
            link="https://www.youtube.com/watch?v=f4G_gpyG7HM&ab_channel=YouxGroup"
          />
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
