import { Accordion, Group, List, Stack, Text } from "@mantine/core";
import { YouTubeEmbed } from "./youtubeEmbed";
import { AccordionEmpreendedor } from "./accordionEmpreendedor";
import { AccordionEmpreendimento } from "./accordionEmpreendimento";
import { AccordionRepresentantes } from "./accordionRepresentantes";
import { AccordionRequerimento } from "./accordionRequerimento";
import { AccordionComplementos } from "./accordionComplementos";
import { AccordionGeometrias } from "./accordionGeometrias";
import { AccordionCondicoes } from "./accordionCondicoes";
import { AccordionDocumentacao } from "./accordionDocumentacao";
import { AccordionEnquadramento } from "./accordionEnquadramento";

export const GuiasRapidos = () => {
  return (
    <Stack gap={"1.5rem"}>
      <Accordion
        defaultValue={"sislam"}
        variant="contained"
        styles={{
          item: {
            backgroundColor: "#fff",
            "&[data-active]": {
              backgroundColor: "#fff",
            },
          },
          chevron: {
            "&[data-rotate]": {
              transform: "rotate(-90deg)",
            },
          },
        }}
        multiple
      >
        <Accordion.Item value={"sislam"}>
          <Accordion.Control>
            <Text fw={"bold"} c={"#333"}>
              Instruções SISLAM - IPAAM
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Accordion
              defaultValue={["entrandoSistema"]}
              variant="contained"
              styles={{
                item: {
                  backgroundColor: "#fff",
                  "&[data-active]": {
                    backgroundColor: "#fff",
                  },
                },
                chevron: {
                  "&[data-rotate]": {
                    transform: "rotate(-90deg)",
                  },
                },
              }}
              multiple
            >
              <Accordion.Item value={"entrandoSistema"}>
                <Accordion.Control>
                  <Text fw={"bold"} c={"#333"}>
                    Entrando no sistema
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Group justify="space-between" align="flex-start">
                    <Stack gap={"1rem"} w={"62%"}>
                      <List>
                        <List.Item>
                          Acesse o SISLAM utilizando seu CPF/CNPJ e a senha
                          cadastrada e clique em “Entrar”
                        </List.Item>
                        <List.Item>
                          Se for seu primeiro acesso, o IPAAM terá enviado um
                          e-mail de ativação com uma senha padrão. Alterá-la é
                          obrigatório.
                        </List.Item>
                        <List.Item>
                          Caso tenha esquecido a senha, clique em "Não consigo
                          entrar" e siga as instruções para redefini-la.
                        </List.Item>
                        <List.Item>
                          A opção "Criar uma conta" está disponível para novos
                          usuários, redirecionando ao módulo{" "}
                          <Text span fw={700}>
                            Entrada Única
                          </Text>
                          .
                        </List.Item>
                      </List>
                    </Stack>
                    <YouTubeEmbed
                      title="Entrando no sistema"
                      link="https://www.youtube.com/watch?v=f4G_gpyG7HM&ab_channel=YouxGroup"
                    />
                  </Group>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value={"navegandoTelaInicial"}>
                <Accordion.Control>
                  <Text fw={"bold"} c={"#333"}>
                    Navegando na Tela Inicial (Listagem de Protocolos)
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Group justify="space-between" align="flex-start">
                    <Stack gap={"1.5rem"} w={"62%"}>
                      <Text>
                        Após realizar o login, você será redirecionado para a{" "}
                        <Text span fw={700}>
                          Listagem de Protocolos
                        </Text>
                        , onde é possível:
                      </Text>
                      <List type="ordered" spacing={"1rem"}>
                        <List.Item>
                          <Text span fw={700}>
                            Visualizar Protocolos:
                          </Text>{" "}
                          Todos os protocolos onde você é o empreendedor ou
                          responsável serão exibidos.
                        </List.Item>
                        <List.Item>
                          <Text span fw={700}>
                            Aplicar Filtros:
                          </Text>{" "}
                          Use os filtros disponíveis para localizar protocolos
                          específicos. As opções incluem:
                          <List spacing={"0.2rem"}>
                            <List.Item>
                              <Text span fw={700}>
                                Processo;
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text span fw={700}>
                                Protocolo;
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text span fw={700}>
                                Empreendedor;
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text span fw={700}>
                                Tipo de Requerimento;
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text span fw={700}>
                                Status;
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text span fw={700}>
                                Municípios;
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text span fw={700}>
                                Responsável pela gestão do protocolo;
                              </Text>
                            </List.Item>
                            <List.Item>
                              <Text span fw={700}>
                                Data de Mudança de Status.
                              </Text>
                            </List.Item>
                          </List>
                          Após definir os critérios, clique em{" "}
                          <Text span fw={700}>
                            "Filtrar"{" "}
                          </Text>{" "}
                          para aplicar os filtros ou em{" "}
                          <Text span fw={700}>
                            "Limpar filtro"
                          </Text>{" "}
                          para reiniciar a busca.
                        </List.Item>
                        <List.Item>
                          <Text span fw={700}>
                            Realizar Ações nos Protocolos:
                          </Text>
                          <List spacing={"0.2rem"}>
                            <List.Item>
                              Cada protocolo possui ações específicas dependendo
                              de seu{" "}
                              <Text span fw={700}>
                                status.
                              </Text>
                            </List.Item>
                            <List.Item>
                              Para acessá-las, clique no ícone de três barras na
                              última coluna da listagem.
                            </List.Item>
                          </List>
                          <Stack gap={"1.5rem"} mt={"1.5rem"}>
                            {" "}
                            <Text fw={700} size="xl">
                              Ações Disponíveis por Status
                            </Text>
                            <Stack gap={"0.5rem"}>
                              <Text fw={700}>
                                Para Protocolos com Status “Vigente”:
                              </Text>
                              <List>
                                <List.Item>
                                  <Text span fw={700}>
                                    Central do Protocolo:{" "}
                                  </Text>
                                  Acesse detalhes completos do protocolo.
                                </List.Item>
                                <List.Item>
                                  <Text span fw={700}>
                                    Baixar Licença:{" "}
                                  </Text>
                                  Disponível apenas para licenças vigentes.
                                </List.Item>
                                <List.Item>
                                  <Text span fw={700}>
                                    Atualização de Fase:{" "}
                                  </Text>
                                  Atualize o protocolo entre as fases de Licença
                                  Prévia, Licença de Instalação, Licença de
                                  Operação e Renovação.
                                </List.Item>
                                <List.Item>
                                  <Text span fw={700}>
                                    Renovação de Fase:{" "}
                                  </Text>
                                  Exclusiva para casos em que a fase anterior
                                  não foi concluída no prazo.
                                </List.Item>
                              </List>
                              <Text fw={700}>
                                Observação: As opções Atualização de Fase e
                                Renovação de Fase aplicam-se apenas a protocolos
                                com licença já emitida.
                              </Text>
                            </Stack>
                            <Stack gap={"0.5rem"}>
                              <Text fw={700}>
                                Para Protocolos com Status "Cadastro":
                              </Text>
                              <List>
                                <List.Item>
                                  <Text span fw={700}>
                                    Excluir Cadastro:{" "}
                                  </Text>
                                  Remove o protocolo do sistema.
                                </List.Item>
                                <List.Item>
                                  <Text span fw={700}>
                                    Continuar Cadastro:{" "}
                                  </Text>
                                  Permite retomar o preenchimento.
                                </List.Item>
                              </List>
                            </Stack>
                            <Stack gap={"0.5rem"}>
                              <Text fw={700}>
                                Para Protocolos com Status "Em Análise":
                              </Text>
                              <List>
                                <List.Item>
                                  <Text span fw={700}>
                                    Central do Protocolo:{" "}
                                  </Text>
                                  Acesse informações detalhadas.
                                </List.Item>
                                <List.Item>
                                  <Text span fw={700}>
                                    Solicitar Atualização:{" "}
                                  </Text>
                                  Altere informações durante a análise. Requer
                                  pagamento de uma taxa de R$18,93. As
                                  informações que podem ser ajustadas incluem:
                                  <List>
                                    <List.Item>
                                      Endereço do empreendimento e de
                                      correspondência;
                                    </List.Item>
                                    <List.Item>
                                      Parâmetros (CNAE, número de empregados e
                                      área útil em hectares);
                                    </List.Item>
                                    <List.Item>
                                      Atividades secundárias e complementares;
                                    </List.Item>
                                    <List.Item>
                                      Vigência da licença requerida.
                                    </List.Item>
                                  </List>
                                </List.Item>
                              </List>
                            </Stack>
                          </Stack>
                        </List.Item>
                        <List.Item>
                          <Text span fw={700}>
                            Anexar Arquivo(s):
                          </Text>{" "}
                          Insira novos documentos para aprovação no módulo de
                          análise. Esta ação também exige uma taxa de R$18,93.
                        </List.Item>
                        <List.Item>
                          <Text span fw={700}>
                            Cadastrar Novo Requerimento:
                          </Text>{" "}
                          Para iniciar um novo protocolo, clique em{" "}
                          <Text span fw={700}>
                            "Cadastrar Novo Requerimento"
                          </Text>{" "}
                          no topo da página.
                        </List.Item>
                      </List>
                    </Stack>
                    <YouTubeEmbed
                      title="Entrando no sistema"
                      link="https://www.youtube.com/watch?v=f4G_gpyG7HM&ab_channel=YouxGroup"
                    />
                  </Group>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value={"cadastrandoRequerimento"}>
                <Accordion.Control>
                  <Text fw={"bold"} c={"#333"}>
                    Cadastrando um novo requerimento (Licenciamento Ambiental)
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Stack gap={"1rem"}>
                    <Text fw={700}>Início do Cadastro</Text>
                    <List spacing={"0.5rem"}>
                      <List.Item>
                        Na página inicial de{" "}
                        <Text span fw={700}>
                          Listagem de Protocolos
                        </Text>
                        , clique no botão no canto superior direito:{" "}
                        <Text span fw={700}>
                          "Cadastrar Novo Requerimento".
                        </Text>
                      </List.Item>
                      <List.Item>
                        O processo de cadastro é dividido em 9 etapas:
                        <List mt={"0.5rem"} type="ordered" spacing={"0.2rem"}>
                          <List.Item>Empreendedor</List.Item>
                          <List.Item>Empreendimento</List.Item>
                          <List.Item>Representantes</List.Item>
                          <List.Item>Requerimento</List.Item>
                          <List.Item>Complementos</List.Item>
                          <List.Item>Geometrias</List.Item>
                          <List.Item>Condições</List.Item>
                          <List.Item>Documentação</List.Item>
                          <List.Item>Enquadramento</List.Item>
                        </List>
                      </List.Item>
                    </List>
                    <Accordion
                      variant="contained"
                      styles={{
                        item: {
                          backgroundColor: "#fff",
                          "&[data-active]": {
                            backgroundColor: "#fff",
                          },
                        },
                        chevron: {
                          "&[data-rotate]": {
                            transform: "rotate(-90deg)",
                          },
                        },
                      }}
                      multiple
                    >
                      <AccordionEmpreendedor />
                      <AccordionEmpreendimento />
                      <AccordionRepresentantes />
                      <AccordionRequerimento />
                      <AccordionComplementos />
                      <AccordionGeometrias di={false} />
                      <AccordionCondicoes />
                      <AccordionDocumentacao />
                      <AccordionEnquadramento />
                    </Accordion>
                  </Stack>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value={"cadastrandoDI"}>
                <Accordion.Control>
                  <Text fw={"bold"} c={"#333"}>
                    Cadastrando uma DI (Declaração de Inexigibilidade)
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <Group justify="space-between" align="flex-start">
                    <Stack gap={"1rem"} w={"62%"}>
                      <Stack gap={"0.5rem"}>
                        <Text>
                          A declaração de inexigibilidade é um tipo de
                          requerimento que formaliza a dispensa de licença
                          ambiental para atividades específicas.
                        </Text>
                        <Text>Este processo é composto por 7 etapas:</Text>
                      </Stack>
                      <List type="ordered" spacing={"1.5rem"}>
                        <List.Item>
                          <Text fw={700}>Inicie o Cadastro</Text>
                          <List>
                            <List.Item>
                              Na{" "}
                              <Text span fw={700}>
                                Listagem de Protocolos,
                              </Text>{" "}
                              clique em{" "}
                              <Text span fw={700}>
                                "Cadastrar Novo Requerimento"
                              </Text>{" "}
                              no canto superior direito.
                            </List.Item>
                          </List>
                        </List.Item>
                        <List.Item>
                          <Text fw={700}>
                            Preencha as Informações do Empreendimento
                          </Text>
                          <List mb={"0.5rem"}>
                            <List.Item>
                              Vincule ou cadastre um empreendedor (Etapa 1) e um
                              empreendimento (Etapa 2), conforme já detalhado
                              nas etapas anteriores, ou abrindo as listas
                              abaixo.
                            </List.Item>
                          </List>
                          <AccordionEmpreendedor />
                          <AccordionEmpreendimento />
                        </List.Item>
                        <List.Item>
                          <Text fw={700} mb={"0.5rem"}>
                            Preencha os dados de representantes/responsáveis
                            (não obrigatória)
                          </Text>
                          Preencha as informações conforme já detalhado nas
                          etapas anteriores, ou abrindo a lista abaixo.
                          <AccordionRepresentantes />
                        </List.Item>
                        <List.Item>
                          <Text fw={700}>Preencha o Requerimento</Text>
                          <List type="ordered" spacing={"0.4rem"}>
                            <List.Item>
                              Selecione o tipo de requerimento:{" "}
                              <Text span fw={700}>
                                Dispensa
                              </Text>
                            </List.Item>
                            <List.Item>
                              Selecione o tipo de requerimento:{" "}
                              <Text span fw={700}>
                                Dispensa de Licenciamento
                              </Text>
                            </List.Item>
                            <List.Item>
                              Vigência requerida:{" "}
                              <Text span fw={700}>
                                Indicar o tempo em anos da vigência requerida.
                              </Text>
                            </List.Item>
                          </List>
                        </List.Item>
                        <List.Item>
                          <Text fw={700}>Valide e Insira Geometrias</Text>
                          <List type="ordered" spacing={"0.4rem"}>
                            <List.Item>
                              Caso solicitado, insira a{" "}
                              <Text span fw={700}>
                                geometria do empreendimento
                              </Text>{" "}
                              para delimitar sua localização no mapa.
                            </List.Item>
                            <List.Item mb={"0.5rem"}>
                              Siga o passo a passo abaixo:
                            </List.Item>
                            <AccordionGeometrias di={true} />
                          </List>
                        </List.Item>
                        <List.Item>
                          <Text span fw={700}>
                            Anexe a Documentação
                          </Text>
                          <List type="ordered" spacing={"0.4rem"}>
                            <List.Item>
                              Verifique na{" "}
                              <Text span fw={700}>
                                Etapa de Documentação
                              </Text>{" "}
                              os documentos obrigatórios e opcionais para a
                              declaração de inexigibilidade.
                            </List.Item>
                            <List.Item>
                              Faça o upload dos documentos na coluna de ações e
                              certifique-se de que estão no formato correto.
                            </List.Item>
                            <List.Item>
                              No final da página selecione a caixa que confirma
                              a veracidade dos documentos e informações
                              inseridas.
                            </List.Item>
                          </List>
                        </List.Item>
                        <List.Item>
                          <Text fw={700}>Enquadramento</Text>
                          <List type="ordered" spacing={"0.4rem"}>
                            <List.Item>
                              <Text fw={700}>Revise o Resumo do Protocolo</Text>
                              <List>
                                <List.Item>
                                  Analise todas as informações cadastradas.
                                  Certifique-se de que os dados estão corretos e
                                  consistentes com o que foi preenchido nas
                                  etapas anteriores.
                                </List.Item>
                              </List>
                            </List.Item>
                            <List.Item>
                              <Text fw={700}>Taxa de Expediente</Text>
                              <List spacing={"0.4rem"}>
                                <List.Item>
                                  Esta solicitação não exige o pagamento da taxa
                                  de licenciamento.
                                </List.Item>
                                <List.Item>
                                  No entanto, é necessário pagar a taxa de
                                  expediente, no valor de R$ 18,93.
                                </List.Item>
                              </List>
                            </List.Item>
                            <List.Item>
                              <Text fw={700}>Finalize o Protocolo</Text>
                              <List spacing={"0.4rem"}>
                                <List.Item>
                                  Clique no botão "Finalizar" para enviar o
                                  protocolo.
                                </List.Item>
                                <List.Item>
                                  Uma janela de confirmação será exibida. Clique
                                  em "Confirmar Solicitação" para concluir o
                                  processo.
                                </List.Item>
                              </List>
                            </List.Item>
                          </List>
                        </List.Item>
                      </List>
                    </Stack>
                    <YouTubeEmbed
                      title="Entrando no sistema"
                      link="https://www.youtube.com/watch?v=f4G_gpyG7HM&ab_channel=YouxGroup"
                    />
                  </Group>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value={"centralProtocolo"}>
                <Accordion.Control>
                  <Text fw={"bold"} c={"#333"}>
                    A Central do Protocolo
                  </Text>
                </Accordion.Control>
                <Accordion.Panel>
                  <List spacing={"1.5rem"} type="ordered">
                    <List.Item>
                      <Text fw={700}>O que é a Central do Protocolo</Text>A
                      Central do Protocolo é um espaço onde você pode:
                      <List spacing={"0.4rem"}>
                        <List.Item>
                          Consultar informações completas sobre um protocolo.
                        </List.Item>
                        <List.Item>
                          Visualizar pendências, notificações e condicionantes.
                        </List.Item>
                        <List.Item>
                          Alterar dados do protocolo, conforme permitido.
                        </List.Item>
                        <List.Item>
                          Gerenciar a relação com o órgão responsável pela
                          análise.
                        </List.Item>
                      </List>
                    </List.Item>
                    <List.Item>
                      <Text fw={700}>Como Acessar a Central do Protocolo</Text>
                      <List spacing={"0.4rem"} type="ordered">
                        <List.Item>
                          Na{" "}
                          <Text span fw={700}>
                            Listagem de Protocolos,
                          </Text>{" "}
                          identifique o protocolo desejado.
                        </List.Item>
                        <List.Item>
                          Clique no ícone de{" "}
                          <Text span fw={700}>
                            três barras
                          </Text>{" "}
                          na última coluna para acessar as ações disponíveis.
                        </List.Item>
                        <List.Item>
                          Selecione{" "}
                          <Text span fw={700}>
                            Central do Protocolo.
                          </Text>
                        </List.Item>
                        Nota: A Central só estará disponível para protocolos que
                        já foram finalizados na etapa de cadastro e possuem
                        número de protocolo atribuído.
                      </List>
                    </List.Item>
                    <Group
                      justify="space-between"
                      align="flex-start"
                      mt={"1.5rem"}
                    >
                      <List.Item w={"53%"}>
                        <Text fw={700}>
                          Funcionalidades da Central do Protocolo
                        </Text>
                        <List type="ordered" spacing={"1rem"}>
                          <List.Item>
                            <Text fw={700}>Resumo do Protocolo</Text>
                            <List>
                              <List.Item>
                                A primeira aba exibe as informações principais:
                                <List type="ordered" spacing={"0.4rem"}>
                                  <List.Item>
                                    <Text span fw={700}>
                                      Dados do Protocolo:
                                    </Text>{" "}
                                    Número, tipo de requerimento e status.
                                  </List.Item>
                                  <List.Item>
                                    <Text span fw={700}>
                                      Empreendedor e Responsável:
                                    </Text>{" "}
                                    Informações do responsável pelo protocolo.
                                  </List.Item>
                                  <List.Item>
                                    <Text span fw={700}>
                                      Atividades e Parâmetros:
                                    </Text>{" "}
                                    Atividade principal e complementares
                                    cadastradas, incluindo CNAEs e área útil.
                                  </List.Item>
                                  <List.Item>
                                    <Text span fw={700}>
                                      Localização:
                                    </Text>{" "}
                                    Geometrias e endereço do empreendimento.
                                  </List.Item>
                                  <List.Item>
                                    <Text span fw={700}>
                                      Outras Informações:
                                    </Text>{" "}
                                    Dados cadastrados nas etapas anteriores,
                                    como condições e complementos (se
                                    aplicáveis).
                                  </List.Item>
                                </List>
                              </List.Item>
                              <List.Item>
                                <Text fw={700}>Aba de Notificações</Text>
                                <List spacing={"0.4rem"}>
                                  <List.Item>
                                    Exibe notificações enviadas pelo órgão
                                    responsável para ajustar ou corrigir o
                                    protocolo.
                                  </List.Item>
                                  <List.Item>
                                    Os protocolos que receberam notificações são
                                    destacados com um{" "}
                                    <Text span fw={700}>
                                      ícone de alerta em vermelho,
                                    </Text>{" "}
                                    facilitando a identificação visual na
                                    listagem.
                                  </List.Item>
                                </List>
                                <List type="ordered" spacing={"0.4rem"}>
                                  <List.Item>
                                    Localize a notificação na lista.
                                  </List.Item>
                                  <List.Item>
                                    Clique em{" "}
                                    <Text span fw={700}>
                                      Responder
                                    </Text>{" "}
                                    para visualizar os detalhes:
                                    <List spacing={"0.4rem"}>
                                      <List.Item>
                                        <Text span fw={700}>
                                          Descrição:
                                        </Text>{" "}
                                        Motivo da notificação, informado pelo
                                        analista.
                                      </List.Item>
                                      <List.Item>
                                        <Text span fw={700}>
                                          Documentos Anexados:
                                        </Text>{" "}
                                        Baixe para entender o que precisa ser
                                        corrigido.
                                      </List.Item>
                                    </List>
                                  </List.Item>
                                  <List.Item>
                                    Escolha uma das opções:
                                    <List spacing={"0.4rem"}>
                                      <List.Item>
                                        <Text fw={700}>
                                          Atualizar Protocolo:
                                        </Text>
                                        <List spacing={"0.4rem"}>
                                          <List.Item>
                                            Clique em Atualizar Protocolo para
                                            corrigir as informações.
                                          </List.Item>
                                          <List.Item>
                                            As etapas de cadastro serão
                                            reabertas para edição, mas algumas
                                            partes (como empreendedor e tipo de
                                            requerimento) não poderão ser
                                            alteradas.
                                          </List.Item>
                                          <List.Item>
                                            Realize as alterações necessárias,
                                            finalize a retificação e confirme.
                                          </List.Item>
                                        </List>
                                      </List.Item>
                                      <List.Item>
                                        <Text fw={700}>Justificar:</Text>
                                        <List spacing={"0.4rem"}>
                                          <List.Item>
                                            Insira uma justificativa explicando
                                            porque não será possível atender à
                                            notificação.
                                          </List.Item>
                                          <List.Item>
                                            Note que essa ação não permite
                                            editar os dados.
                                          </List.Item>
                                        </List>
                                      </List.Item>
                                    </List>
                                  </List.Item>
                                </List>
                              </List.Item>
                            </List>
                          </List.Item>
                        </List>
                      </List.Item>
                      <YouTubeEmbed
                        title="Entrando no sistema"
                        link="https://www.youtube.com/watch?v=f4G_gpyG7HM&ab_channel=YouxGroup"
                      />
                    </Group>
                    <Group
                      justify="space-between"
                      align="flex-start"
                      mt={"1.5rem"}
                    >
                      <List.Item w={"53%"}>
                        <Text fw={700}>Atendimento de Condicionantes</Text>
                        <List>
                          <List.Item>
                            A aba{" "}
                            <Text span fw={700}>
                              Condicionantes
                            </Text>{" "}
                            lista os requisitos técnicos ou documentais que
                            devem ser atendidos para o andamento ou manutenção
                            do protocolo.
                          </List.Item>
                        </List>
                        <List spacing={"0.4rem"} type="ordered">
                          <List.Item>
                            Verifique as informações da condicionante:
                            <List spacing={"0.4rem"}>
                              <List.Item>
                                <Text span fw={700}>
                                  Número:
                                </Text>{" "}
                                Identificação da condicionante.
                              </List.Item>
                              <List.Item>
                                <Text span fw={700}>
                                  Status:
                                </Text>{" "}
                                Exibe se a condicionante está disponível para
                                atendimento.
                              </List.Item>
                              <List.Item>
                                <Text span fw={700}>
                                  Frequência:
                                </Text>{" "}
                                Algumas condicionantes podem ser recorrentes
                                (ex.: envio mensal ou anual).
                              </List.Item>
                            </List>
                          </List.Item>
                          <List.Item>
                            Caso esteja liberada para atendimento:
                            <List spacing={"0.4rem"}>
                              <List.Item>
                                Clique em{" "}
                                <Text span fw={700}>
                                  Atender Condicionante.
                                </Text>
                              </List.Item>
                              <List.Item>
                                Anexe os documentos exigidos e envie ao órgão
                                responsável para análise.
                              </List.Item>
                            </List>
                          </List.Item>
                        </List>
                      </List.Item>
                      <YouTubeEmbed
                        title="Entrando no sistema"
                        link="https://www.youtube.com/watch?v=f4G_gpyG7HM&ab_channel=YouxGroup"
                      />
                    </Group>
                    <Group
                      justify="space-between"
                      align="flex-start"
                      mt={"1.5rem"}
                    >
                      <List.Item w={"53%"}>
                        <Text fw={700}>Alteração de Responsável</Text>
                        <List>
                          <List.Item>
                            Na aba de representantes, você pode alterar o
                            responsável técnico ou legal pelo protocolo:
                          </List.Item>
                        </List>
                        <List type="ordered" spacing={"0.4rem"}>
                          <List.Item>
                            Selecione o responsável atual e clique em{" "}
                            <Text span fw={700}>
                              Alterar.
                            </Text>
                          </List.Item>
                          <List.Item>
                            Insira os dados do novo responsável (nome,
                            CPF/CNPJ).
                          </List.Item>
                          <List.Item>
                            Caso o responsável seja removido, o sistema
                            atribuirá automaticamente o papel ao empreendedor
                            cadastrado.
                          </List.Item>
                        </List>
                      </List.Item>
                      <YouTubeEmbed
                        title="Entrando no sistema"
                        link="https://www.youtube.com/watch?v=f4G_gpyG7HM&ab_channel=YouxGroup"
                      />
                    </Group>
                    <List.Item>
                      <Text fw={700}>Atualizações do Requerimento</Text>
                      <List>
                        <List.Item>
                          Relacionada às ações realizadas enquanto o protocolo
                          está em análise:
                        </List.Item>
                      </List>
                      <List type="ordered" spacing={"0.4rem"}>
                        <List.Item>
                          <Text span fw={700}>
                            Solicitar Atualização:
                          </Text>
                          <List spacing={"0.4rem"}>
                            <List.Item>
                              Clique em{" "}
                              <Text span fw={700}>
                                Solicitar Atualização
                              </Text>{" "}
                              para alterar dados específicos do protocolo (ex.:
                              endereço, parâmetros ou representantes).
                            </List.Item>
                            <List.Item>
                              Realize as alterações, finalize e confirme.
                            </List.Item>
                          </List>
                        </List.Item>
                        <List.Item>
                          <Text span fw={700}>
                            Anexar Arquivos:
                          </Text>
                          <List spacing={"0.4rem"}>
                            <List.Item>
                              Utilize esta opção para adicionar documentos
                              complementares que serão avaliados pelo órgão
                              responsável.
                            </List.Item>
                            <List.Item>
                              <Text span fw={700}>
                                Histórico de Alterações:
                              </Text>{" "}
                              Todas as ações realizadas (notificações
                              respondidas, alterações e condicionantes
                              atendidas) ficam registradas para consulta futura.
                            </List.Item>
                          </List>
                        </List.Item>
                      </List>
                    </List.Item>
                  </List>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Stack>
  );
};
