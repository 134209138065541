import { Accordion, Group, List, Stack, Text } from "@mantine/core";
import { YouTubeEmbed } from "../youtubeEmbed";

export const AccordionGeometrias = ({ di }) => {
  return (
    <Accordion.Item value={"geometrias"}>
      <Accordion.Control>
        <Text fw={"bold"} c={"#333"}>
          {di ? "Etapa 5: Geometrias" : "Etapa 6: Geometrias"}
        </Text>
      </Accordion.Control>
      <Accordion.Panel>
        <Group justify="space-between" align="flex-start">
          <Stack gap={"1rem"} w={"55%"}>
            <List type="ordered" spacing={"1.5rem"}>
              <List.Item>
                <Text fw={700}>Escolha o Método de Inserção</Text>
                <List spacing={"0.4rem"}>
                  <List.Item>
                    <Text fw={700}>Upload de Arquivo:</Text>
                    <List>
                      <List.Item>
                        Clique no ícone de upload na última coluna de "Ações"
                        para enviar arquivos de geometria.
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    <Text fw={700}>Inserção Manual:</Text>
                    <List>
                      <List.Item>
                        Clique no ícone de lápis na última coluna de "Ações"
                        para adicionar manualmente as geometrias.
                      </List.Item>
                    </List>
                  </List.Item>
                  Nota: A geometria do empreendimento é obrigatória, enquanto as
                  geometrias de hidrografia, áreas de preservação ou áreas
                  antropizadas são opcionais.
                </List>
              </List.Item>
              <List.Item>
                <Text fw={700}>Valide as Geometrias</Text>
                <List spacing={"0.5rem"} type="ordered">
                  <List.Item>
                    Após inserir as geometrias do empreendimento e das
                    atividades:
                    <List spacing={"0.4rem"}>
                      <List.Item>
                        O sistema verificará automaticamente se as geometrias
                        das atividades estão{" "}
                        <Text span fw={700}>
                          dentro dos limites da geometria do empreendimento.
                        </Text>
                      </List.Item>
                      <List.Item>
                        Caso alguma atividade esteja fora do limite, será
                        exibida uma mensagem de alerta.
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    Se houver discrepância entre a área declarada anteriormente
                    nos parâmetros e a área da geometria inserida:
                    <List spacing={"0.4rem"}>
                      <List.Item>
                        Clique em{" "}
                        <Text span fw={700}>
                          "Atualizar Valor"
                        </Text>{" "}
                        para corrigir os valores automaticamente.
                      </List.Item>
                    </List>
                  </List.Item>
                  <List.Item>
                    Utilize o painel lateral para ativar ou desativar as camadas
                    de geometrias, permitindo uma visualização mais clara e
                    precisa do mapa.
                  </List.Item>
                </List>
              </List.Item>
              <List.Item>
                <Text fw={700}>Finalize a Etapa</Text>
                <List>
                  <List.Item>
                    Após revisar todas as geometrias, clique em{" "}
                    <Text span fw={700}>
                      "Avançar"
                    </Text>{" "}
                    para seguir para a próxima etapa do protocolo.
                  </List.Item>
                </List>
              </List.Item>
            </List>
          </Stack>
          <YouTubeEmbed
            title="Entrando no sistema"
            link="https://www.youtube.com/watch?v=f4G_gpyG7HM&ab_channel=YouxGroup"
          />
        </Group>
      </Accordion.Panel>
    </Accordion.Item>
  );
};
