import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, Divider, Group, Stack, Text } from "@mantine/core";
import { Fragment, useState } from "react";

export const Gerencias = () => {
  const [gerenciaSelecionada, setGerenciaSelecionada] = useState({
    titulo: "GELI - Gerência de Licenciamento Industrial",
    descricao:
      "Responsável por licenciar, monitorar e controlar as atividades relacionadas às atividades industriais e construções civis; analisar planos, projetos e estudos referentes ao licenciamento ambiental. Minutar as licenças ambientais.",
    contato: "(92) 2123-6724",
    email: "industria@ipaam.am.gov.br",
  });

  const gerencias = [
    {
      titulo: "GELI - Gerência de Licenciamento Industrial",
      descricao:
        "Responsável por licenciar, monitorar e controlar as atividades relacionadas às atividades industriais e construções civis; analisar planos, projetos e estudos referentes ao licenciamento ambiental. Minutar as licenças ambientais.",
      contato: "(92) 2123-6724",
      email: "industria@ipaam.am.gov.br",
    },
    {
      titulo: "GECF - Gerência de Controle Florestal",
      descricao:
        "Responsável por licenciar, monitorar e controlar as atividades de base florestal. Analisar as solicitações de declarações, autorizações, operar o sistema de controle florestal – DOF e o SINAFLOR. Analisar planos, projetos e estudos referentes ao licenciamento ambiental.",
      contato: "(92) 2123-6708 / 2123-6756 / 98448-0384",
      email: "floresta@ipaam.am.gov.br",
    },
    {
      titulo: "GCAP - Gerência de Controle Agropecuário",
      descricao:
        "Responsável por licenciar, monitorar e controlar atividades agrícolas, pecuárias, agroindustrial, comercialização e armazenamento de agrotóxicos, abatedouros, matadouros e suspensão vegetal para uso alternativo do solo em imóveis rurais.",
      contato: "(92) 2123-6763 e 98419-0369",
      email: "agropecuária@ipaam.am.gov.br",
    },
    {
      titulo: "GERM - Gerência de Recursos Minerais",
      descricao:
        "Responsável por licenciar, monitorar e controlar as atividades relacionadas ao uso dos Recursos Minerais. Analisar planos, projetos e estudos referentes ao licenciamento ambiental. Minutar as licenças ambientais.",
      contato: "(92) 2123-6749 / 98455-7653",
      email: "germ@ipaam.am.gov.br",
    },
    {
      titulo: "GECP - Gerência de Controle de Pesca",
      descricao:
        "Responsável por licenciar, monitorar e controlar as atividades de aquicultura, pesca amadora, beneficiamento de pescado, controle de estoque de pescado declarado durante o período do defeso estabelecido por normas estaduais, analisar planos, projetos e estudos referente ao licenciamento ambiental.",
      contato: "(92) 2123-6762 / 98441-8303",
      email: "pesca@ipaam.am.gov.br",
    },
    {
      titulo: "GEPR – Gerência de Gestão de Documentos e Protocolo",
      descricao:
        "Responsável pelo atendimento ao público, orientando quanto a necessidade ou não de licenciamento ambiental; receber e protocolar documentos diversos, inclusive dos centros multifuncionais dos municípios de Parintins, Humaitá e Boca do Acre.",
      contato: "(92) 2123-6716",
      email: "",
    },
    {
      titulo: "GERH - Gerência de Recursos Hídricos",
      descricao:
        "Responsável por licenciar, monitorar e controlar as atividades relacionadas a uso dos Recursos Hídricos; analisar os pedidos de outorga de direito de uso de recursos hídricos; Analisar planos, projetos e estudos referentes ao licenciamento ambiental.",
      contato: "(92) 2123-6734",
      email: "gerh@ipaam.am.gov.br / gerh.ipaam@gmail.com",
    },
    {
      titulo: "GGEO - Gerência de Geoprocessamento",
      descricao:
        "É responsável pela análise geoespacial dos empreendimentos apresentados no IPAAM. Informando à Áreas Protegidas, Áreas com restrições e sob uso condicionado, situação fundiária e sobreposição de dados espaciais por meio de parecer técnico e carta imagem e mapas, verificando os dados em relação à Áreas Protegidas, Áreas com restrições e sob uso condicionado, situação fundiária e sobreposição de dados espaciais.",
      contato: "(92) 2123-6752 / 98411-2520",
      email: "ggeo@ipaam.am.gov.br",
    },
    {
      titulo: "GFAU - Gerência de Fauna",
      descricao:
        "Esta gerência é responsável por licenciar, monitorar e controlar as atividades relacionadas ao uso de fauna. Faz a gestão da fauna silvestre em cativeiro e de autorizações e análises dos impactos causados à fauna por empreendimentos licenciados pelo IPAAM.",
      contato: "(92) 2123-6739",
      email: "fauna.ipaam@gmail.com",
    },
    {
      titulo: "GEFA - Gerência de Fiscalização Ambiental",
      descricao:
        "É responsável pela fiscalização, tendo como objetivo desenvolver atividades preventivas, corretivas e repressivas relativas à proteção e defesa do meio ambiente.",
      contato: "(92) 2123-6715 / 2123-6729 / 98557-9454",
      email: "fiscalizacao@ipaam.am.gov.br / denuncia@ipaam.am.gov.br",
    },
  ];

  return (
    <Stack gap={"1rem"} mb={"2rem"}>
      <Text fz={"xl"}>
        Saiba mais sobre{" "}
        <Text span fw={"bolder"} fz={"xl"}>
          Gerências
        </Text>
        <Card
          shadow="sm"
          radius="xl"
          withBorder
          bg={"#F2EEE3"}
          mt={"0.5rem"}
          padding={0}
        >
          <Box
            p={"lg"}
            style={{
              backgroundImage: `url('/images/florestal.png')`,
              backgroundPosition: "right",
              backgroundRepeat: `no-repeat`,
              backgroundSize: "contain",
            }}
          >
            <Group gap={"3rem"} align="flex-start">
              <Stack gap={"0.5rem"}>
                {gerencias.map((gerencia, index) => (
                  <Fragment key={index}>
                    <Button
                      variant="subtle"
                      radius="xl"
                      w={450}
                      fw={"lighter"}
                      c={"#333"}
                      justify="left"
                      onClick={() => setGerenciaSelecionada(gerencia)}
                      bg={
                        gerenciaSelecionada?.titulo === gerencia?.titulo
                          ? "#ffffff"
                          : ""
                      }
                    >
                      {gerencia.titulo}
                    </Button>
                  </Fragment>
                ))}
              </Stack>
              <Divider orientation="vertical" size="sm" />
              <Stack gap={"1.5rem"} w={415}>
                <Text c={"#333"} fw={"bold"} fz={"1.25rem"}>
                  {gerenciaSelecionada.titulo}
                </Text>
                <Text c={"#666"} fz={"1.15rem"}>
                  {gerenciaSelecionada.descricao}
                </Text>
                {gerenciaSelecionada.contato && (
                  <Text c={"#666"} fz={"1.15rem"}>
                    Contato:{" "}
                    <Text span fw={"bold"} fz={"1.15rem"}>
                      {gerenciaSelecionada.contato}
                    </Text>
                  </Text>
                )}
                {gerenciaSelecionada.email && (
                  <Text c={"#666"} fz={"1.15rem"}>
                    Email:{" "}
                    <Text span fw={"bold"} fz={"1.15rem"}>
                      {gerenciaSelecionada.email}
                    </Text>
                  </Text>
                )}
              </Stack>
              {gerenciaSelecionada.descricao && (
                <Stack ml={"auto"} mt={"auto"}>
                  <Button
                    radius="xl"
                    w={250}
                    fw={"lighter"}
                    color="#F6F9F6"
                    c={"#333"}
                    rightSection={<FontAwesomeIcon icon={faChevronRight} />}
                    onClick={() => {
                      window.open("https://www.ipaam.am.gov.br/a-instituicao/");
                    }}
                  >
                    Saiba mais
                  </Button>
                  <Button
                    radius="xl"
                    w={250}
                    fw={"lighter"}
                    color="#F6F9F6"
                    c={"#333"}
                    rightSection={<FontAwesomeIcon icon={faChevronRight} />}
                    mb={"3rem"}
                    onClick={() => {
                      window.open(
                        "https://www.ipaam.am.gov.br/wp-content/uploads/2024/09/Organograma-IPAAM_2024-scaled.jpg"
                      );
                    }}
                  >
                    Organograma do IPAAM
                  </Button>
                </Stack>
              )}
            </Group>
          </Box>
        </Card>
      </Text>
    </Stack>
  );
};
