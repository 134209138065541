import {
  Accordion,
  Button,
  Checkbox,
  Group,
  MultiSelect,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import api from "../../../utils/api";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

export const InformacoesFases = ({
  form,
  atividade,
  setFasesSelecionadas,
  todasFasesString,
  setTipoPessoaFiltro,
}) => {
  const [requisitos, setRequisitos] = useState();
  const [selectAll, setSelectAll] = useState(false);

  const getRequisitos = async (primeiraVez) => {
    const dto = {
      fases:
        atividade?.codigo === "0000"
          ? ["DI"]
          : primeiraVez
          ? atividade.fases
          : form.values.fases,
      idAtividade: atividade.id,
      tipoPessoa: primeiraVez ? ["PF", "PJ"] : form.values.tipoPessoa,
    };
    try {
      const response = await api.post(
        `/api/configurador/atividade/requisitos`,
        dto
      );
      setRequisitos(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      form.setFieldValue("fases", []);
    } else {
      form.setFieldValue("fases", atividade.fases || []);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (atividade?.id) {
      getRequisitos(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atividade?.id]);

  useEffect(() => {
    if (todasFasesString) {
      setFasesSelecionadas(
        form.values.fases.length === 0
          ? todasFasesString || ""
          : form.values.fases.join(", ")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.fases]);

  useEffect(() => {
    const { tipoPessoa } = form.values;

    if (tipoPessoa.length === 0 || tipoPessoa.length === 2) {
      setTipoPessoaFiltro("pessoa física e jurídica");
    } else if (tipoPessoa.length === 1) {
      setTipoPessoaFiltro(
        tipoPessoa[0] === "PF" ? "pessoa física" : "pessoa jurídica"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.tipoPessoa]);

  return (
    <Stack gap={"1.5rem"}>
      <Group justify="space-between">
        <Group gap={"1rem"}>
          <MultiSelect
            w={300}
            onRemove={() => setSelectAll(false)}
            onOptionSubmit={() => {
              const totalFases = atividade?.fases?.length || 0;
              const selectedFases = form.values.fases?.length || 0;

              console.log(totalFases);
              console.log(selectedFases);

              if (totalFases === selectedFases + 1) {
                setSelectAll(true);
              }
            }}
            {...form.getInputProps("fases")}
            size="sm"
            label={
              <Group>
                <Text fw={"lighter"} fz={"0.875rem"}>
                  Fases:
                </Text>{" "}
                {atividade?.fases?.length > 1 && (
                  <Button
                    ml={128}
                    variant="subtle"
                    size="xs"
                    onClick={handleSelectAll}
                  >
                    {selectAll ? "Desmarcar todas" : "Selecionar todas"}
                  </Button>
                )}
              </Group>
            }
            data={atividade?.fases || []}
            searchable
          />
          <Checkbox.Group {...form.getInputProps("tipoPessoa")}>
            <Group mt={"xl"}>
              <Checkbox
                color="green"
                size="sm"
                value="PF"
                label="Pessoa Física"
              />
              <Checkbox
                color="green"
                size="sm"
                value="PJ"
                label="Pessoa Jurídica"
              />
            </Group>
          </Checkbox.Group>
        </Group>
        <Group>
          <Button
            color="green"
            fw={"lighter"}
            radius={"xl"}
            onClick={() => getRequisitos(false)}
          >
            Aplicar filtros
          </Button>
          <Button
            color="green"
            variant="subtle"
            fw={"lighter"}
            radius={"xl"}
            onClick={() => {
              form.setFieldValue("fases", []);
              form.setFieldValue("tipoPessoa", "PF");
            }}
          >
            Limpar
          </Button>
        </Group>
      </Group>
      {requisitos && requisitos.length > 0 ? (
        <Accordion
          variant="contained"
          styles={{
            item: {
              backgroundColor: "#fff",
              "&[data-active]": {
                backgroundColor: "#fff",
              },
            },
            chevron: {
              "&[data-rotate]": {
                transform: "rotate(-90deg)",
              },
            },
          }}
          multiple={true}
          defaultValue={["0"]}
        >
          {requisitos?.map((requisito, index) => (
            <Accordion.Item key={index} value={index.toString()}>
              <Accordion.Control>
                <Text fw={"bold"} c={"#666"}>
                  {requisito?.tituloLista}
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Table highlightOnHover verticalSpacing="md">
                  <Table.Tbody>
                    {requisito?.listaRequisitos.map((r, index) => (
                      <Table.Tr key={r?.nomeRequisito}>
                        <Table.Td w={40}>{index + 1}</Table.Td>
                        <Table.Td>{r?.nomeRequisito}</Table.Td>
                        <Table.Td w={120}>
                          {r?.obrigatorio ? "Obrigatório" : "Opcional"}
                        </Table.Td>
                        <Table.Td w={20}>
                          {r?.linkDocumento ? (
                            <FontAwesomeIcon
                              cursor={"pointer"}
                              icon={faEye}
                              onClick={() => window.open(r?.linkDocumento)}
                            />
                          ) : (
                            <FontAwesomeIcon icon={faEyeSlash} />
                          )}
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      ) : (
        <Text fs="italic">
          Aplique os filtros para visualizar os documentos administrativos e
          técnicos atrelados a atividade/fase selecionada
        </Text>
      )}
    </Stack>
  );
};
