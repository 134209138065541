import { Carousel } from "@mantine/carousel";
import { Card, Image, Stack, Text } from "@mantine/core";
import { useRef } from "react";
import Autoplay from "embla-carousel-autoplay";

export const CarouselComponent = () => {
  const autoplay = useRef(Autoplay({ delay: 8000 }));

  return (
    <Carousel
      slideSize={"20%"}
      slideGap={{ base: 0, sm: "md" }}
      align="start"
      my={"2rem"}
      loop
      plugins={[autoplay.current]}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}
    >
      <Carousel.Slide>
        <Card shadow="sm" padding="md" radius="md" withBorder w={340} h={410}>
          <Card.Section>
            <Image src="/images/consulta.png" height={200} alt="Norway" />
          </Card.Section>
          <Stack mt={"0.5rem"} justify="space-between" h={"100%"}>
            <Stack>
              <Text fw={"bold"} c={"#333"} fz={"1.15rem"}>
                Consulta de processos
              </Text>
              <Text fz={"0.785rem"} fw={"lighter"} c={"#666"}>
                Consulta pública de processos e protocolos gerados no SISLAM.
              </Text>
            </Stack>
            <Text
              c={"green"}
              fw={"lighter"}
              fz={"1.15rem"}
              style={{ cursor: "pointer" }}
              w={100}
              onClick={() => {
                window.open(
                  "https://homologacao.ipaam.am.gov.br/sislam/listagem-protocolo-analise"
                );
              }}
            >
              Acesse aqui
            </Text>
          </Stack>
        </Card>
      </Carousel.Slide>
      <Carousel.Slide>
        <Card shadow="sm" padding="md" radius="md" withBorder w={340} h={410}>
          <Card.Section>
            <Image src="/images/sislam.png" height={200} alt="Norway" />
          </Card.Section>
          <Stack mt={"0.5rem"} justify="space-between" h={"100%"}>
            <Stack>
              <Text fw={"bold"} c={"#333"} fz={"1.15rem"}>
                Site SISLAM
              </Text>
              <Text fz={"0.785rem"} fw={"lighter"} c={"#666"}>
                O Sistema de Licenciamento Ambiental do Amazonas permite
                realizar os requerimentos para atividades utilizadoras dos
                recursos ambientais consideradas efetiva ou potencialmente
                poluidoras previstas na Lei nº 3.785/2012.
              </Text>
            </Stack>
            <Text
              c={"green"}
              fw={"lighter"}
              fz={"1.15rem"}
              style={{ cursor: "pointer" }}
              w={95}
              onClick={() => {
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: "smooth",
                });
              }}
            >
              Saiba mais
            </Text>
          </Stack>
        </Card>
      </Carousel.Slide>
      <Carousel.Slide>
        <Card shadow="sm" padding="md" radius="md" withBorder w={340} h={410}>
          <Card.Section>
            <Image src="/images/pesca.png" height={200} alt="Norway" />
          </Card.Section>
          <Stack mt={"0.5rem"} justify="space-between" h={"100%"}>
            <Stack>
              <Text fw={"bold"} c={"#333"} fz={"1.15rem"}>
                Carteira de pesca
              </Text>
              <Text fz={"0.785rem"} fw={"lighter"} c={"#666"}>
                O Sistema de Registro de Licença de Pesca permite cadastrar
                informações de pessoas físicas para emissão de carteiras de
                pesca amadora no Estado do Amazonas.
              </Text>
            </Stack>
            <Text
              c={"green"}
              fw={"lighter"}
              fz={"1.15rem"}
              style={{ cursor: "pointer" }}
              w={95}
              onClick={() => {
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: "smooth",
                });
              }}
            >
              Saiba mais
            </Text>
          </Stack>
        </Card>
      </Carousel.Slide>
      <Carousel.Slide>
        <Card shadow="sm" padding="md" radius="md" withBorder w={340} h={410}>
          <Card.Section>
            <Image src="/images/entradaUnica.png" height={200} alt="Norway" />
          </Card.Section>
          <Stack mt={"0.5rem"} justify="space-between" h={"100%"}>
            <Stack>
              <Text fw={"bold"} c={"#333"} fz={"1.15rem"}>
                Entrada Única
              </Text>
              <Text fz={"0.785rem"} fw={"lighter"} c={"#666"}>
                Sistema para cadastro inicial dos usuários Pessoa Física e/ou
                Jurídica para acesso em todos os demais sistemas do IPAAM,
                permitindo ainda a gestão de login e senha e dados cadastrados.
              </Text>
            </Stack>
            <Text
              c={"green"}
              fw={"lighter"}
              fz={"1.15rem"}
              style={{ cursor: "pointer" }}
              w={95}
              onClick={() => {
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: "smooth",
                });
              }}
            >
              Saiba mais
            </Text>
          </Stack>
        </Card>
      </Carousel.Slide>
      <Carousel.Slide>
        <Card shadow="sm" padding="md" radius="md" withBorder w={340} h={410}>
          <Card.Section>
            <Image src="/images/sid.png" height={200} alt="Norway" />
          </Card.Section>
          <Stack mt={"0.5rem"} justify="space-between" h={"100%"}>
            <Stack>
              <Text fw={"bold"} c={"#333"} fz={"1.15rem"}>
                SID - AM
              </Text>
              <Text fz={"0.785rem"} fw={"lighter"} c={"#666"}>
                O SID-AM é o instrumento de divulgação das áreas embargadas por
                desmatamento ilegal e as áreas autorizadas para supressão da
                vegetação no estado do Amazonas.
              </Text>
            </Stack>
            <Text
              c={"green"}
              fw={"lighter"}
              fz={"1.15rem"}
              style={{ cursor: "pointer" }}
              w={95}
              onClick={() => {
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: "smooth",
                });
              }}
            >
              Saiba mais
            </Text>
          </Stack>
        </Card>
      </Carousel.Slide>
      <Carousel.Slide>
        <Card shadow="sm" padding="md" radius="md" withBorder w={340} h={410}>
          <Card.Section>
            <Image
              src="/images/geo.png"
              height={200}
              fit="contain"
              alt="Norway"
            />
          </Card.Section>
          <Stack mt={"0.5rem"} justify="space-between" h={"100%"}>
            <Stack>
              <Text fw={"bold"} c={"#333"} fz={"1.15rem"}>
                Geoportal - Geração de Mapa
              </Text>
              <Text fz={"0.785rem"} fw={"lighter"} c={"#666"}>
                O Geoportal Ipaam é um repositório de dados espaciais e não
                espaciais ligados às atividades de licenciamento, fiscalização e
                monitoramento ambiental. Utilize a ferramenta WebGis para criar
                mapas e visualizar informações.
              </Text>
            </Stack>
            <Text
              c={"green"}
              fw={"lighter"}
              fz={"1.15rem"}
              style={{ cursor: "pointer" }}
              w={100}
              onClick={() => {
                window.open("https://gis.ipaam.am.gov.br/geoportal/");
              }}
            >
              Acesse aqui
            </Text>
          </Stack>
        </Card>
      </Carousel.Slide>
      <Carousel.Slide>
        <Card shadow="sm" padding="md" radius="md" withBorder w={340} h={410}>
          <Card.Section>
            <Image src="/images/legislacao.jpeg" height={200} alt="Norway" />
          </Card.Section>
          <Stack mt={"0.5rem"} justify="space-between" h={"100%"}>
            <Stack>
              <Text fw={"bold"} c={"#333"} fz={"1.15rem"}>
                Legislação pertinente
              </Text>
              <Text fz={"0.785rem"} fw={"lighter"} c={"#666"}>
                Através deste acesso serão disponibilizadas legislações
                ambientais estaduais, portarias internas referentes aos sistemas
                entre outras informações.
              </Text>
            </Stack>
            <Text
              c={"green"}
              fw={"lighter"}
              fz={"1.15rem"}
              style={{ cursor: "pointer" }}
              w={95}
              onClick={() => {
                window.scrollTo({
                  top: document.documentElement.scrollHeight,
                  behavior: "smooth",
                });
              }}
            >
              Saiba mais
            </Text>
          </Stack>
        </Card>
      </Carousel.Slide>
    </Carousel>
  );
};
