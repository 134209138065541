import { Button, Card, Group, Stack, Text } from "@mantine/core";
import { useState } from "react";

export const CardInformacoesAtividade = ({
  atividade,
  fasesSelecionadas,
  handleExportPDF,
}) => {
  const [showAll, setShowAll] = useState(false);

  const handleToggle = () => {
    setShowAll(!showAll);
  };

  const displayedCnaes = showAll
    ? atividade?.cnaes
    : atividade?.cnaes?.slice(0, 3);

  return (
    <Card
      shadow="xs"
      padding={"lg"}
      radius="sm"
      style={{
        backgroundColor: "#DDE9ED",
      }}
    >
      <Stack gap={"1.5rem"}>
        <Group justify="space-between" align="flex-start">
          <Stack gap={"0.5rem"}>
            <Text size="1.5rem" c={"#295661"} fw={"bold"}>
              Requisitos para a atividade{" "}
              {atividade?.codigo !== "N/A" &&
                atividade?.codigo !== "0000" &&
                atividade?.codigo}
            </Text>
            <Text size="1.15rem" c={"#295661"}>
              {atividade?.nome}
            </Text>
          </Stack>
          <Button variant="subtle" onClick={handleExportPDF}>
            Exportar PDF
          </Button>
        </Group>
        {atividade?.cnaes && atividade?.cnaes.length > 0 && (
          <Stack gap={"0.5rem"}>
            {displayedCnaes.map((cnae, index) => (
              <Text c={"#295661"} key={index}>
                {cnae}
              </Text>
            ))}
            {atividade.cnaes.length > 3 && (
              <Text
                w={"85px"}
                c={"#295661"}
                onClick={handleToggle}
                style={{
                  cursor: "pointer",
                }}
              >
                {showAll ? "Ver menos" : "Ver mais"}
              </Text>
            )}
          </Stack>
        )}
        <Group gap={"2.5rem"} align="flex-start">
          <Stack gap={"0.5rem"}>
            <Text c={"#295661"}>
              Tipologia:{" "}
              <Text span fw={"bold"}>
                {atividade?.tipologia}
              </Text>
            </Text>
            <Text c={"#295661"}>
              Localização:{" "}
              <Text span fw={"bold"}>
                {atividade?.localizacao}
              </Text>
            </Text>
          </Stack>
          <Stack gap={"0.5rem"}>
            <Text c={"#295661"}>
              Fases:{" "}
              <Text span fw={"bold"}>
                {atividade?.codigo === "0000" ? "DI" : fasesSelecionadas}
              </Text>
            </Text>
            <Text c={"#295661"}>
              PPD:{" "}
              <Text span fw={"bold"}>
                {atividade?.ppd}
              </Text>
            </Text>
          </Stack>
          <Stack gap={"0.5rem"}>
            <Text c={"#295661"}>
              Gerência competente:{" "}
              <Text span fw={"bold"}>
                {atividade?.gerencia}
              </Text>
            </Text>
          </Stack>
        </Group>
      </Stack>
    </Card>
  );
};
